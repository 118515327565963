import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import GraphAddModalProps from "./GraphAddModal.types";
import { GRAPH_TYPE } from "../DataVisualizer/DataVisualizer.types";
import { useCallback, useMemo, useState } from "react";
import { getPossibleDataVisualizers } from "../AnalyzePage.utils";

const GraphAddModal = (props: GraphAddModalProps) => {
  const { respondents, survey, onAdd, onClose, isOpen, addedDataVisualizers } =
    props;

  const [possibleGraphTypes, setPossibleGraphTypes] = useState<
    GRAPH_TYPE[] | null
  >(null);
  const [selectedGraphType, setSelectedGraphType] = useState<GRAPH_TYPE | null>(
    null
  );
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  );

  const possibleDataVisualizers = useMemo(() => {
    if (!survey || !respondents) return [];
    const p = getPossibleDataVisualizers(survey, respondents);
    return p;
    /** Extract questions, their possible graph visualization types and the data for each visualisaiton */
  }, [survey, respondents]);

  const handleOnChangeSelectedGraphId = useCallback(
    (id: string) => {
      setSelectedQuestionId(id);
      const selectedDataVisualizer = possibleDataVisualizers.find(
        (pdv) => pdv.questionId === id
      );
      setPossibleGraphTypes(selectedDataVisualizer?.visualisationTypes ?? null);
      setSelectedGraphType(
        selectedDataVisualizer?.visualisationTypes[0] ?? null
      );
    },
    [possibleDataVisualizers]
  );

  const handleOnClickAddGraph = useCallback(() => {
    if (
      possibleDataVisualizers.length === 0 ||
      !selectedQuestionId ||
      !selectedGraphType ||
      !onAdd
    )
      return;

    const selectedDataVisualizer = possibleDataVisualizers.find(
      (pdv) => pdv.questionId === selectedQuestionId
    );
    if (!selectedDataVisualizer) return;

    const dataVisualizer = {
      questionId: selectedQuestionId,
      questionLabel: selectedDataVisualizer.questionLabel,
      visualisationType: selectedGraphType,
      data: selectedDataVisualizer.data
    };
    onAdd(dataVisualizer);

    setSelectedQuestionId(null);
    setSelectedGraphType(null);
  }, [onAdd, possibleDataVisualizers, selectedGraphType, selectedQuestionId]);

  return (
    <Dialog
      className="graph-add-modal"
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel>{"Välj fråga"}</InputLabel>
            <Select
              value={selectedQuestionId}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChangeSelectedGraphId(value as string);
              }}
              label={
                possibleDataVisualizers.find(
                  (d) => d.questionId === selectedQuestionId
                )?.questionLabel
              }
              sx={{ backgroundColor: "lightgray" }}
            >
              {possibleDataVisualizers
                .filter(
                  (pdv) =>
                    !addedDataVisualizers
                      .map((d) => d.questionId)
                      .includes(pdv.questionId)
                )
                .map((x) => (
                  <MenuItem value={x.questionId}>{x.questionLabel}</MenuItem>
                ))}
            </Select>
          </FormControl>

          {selectedQuestionId && (
            <FormControl fullWidth>
              <InputLabel>{"Välj graftyp"}</InputLabel>
              <Select
                value={selectedGraphType}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedGraphType(value as GRAPH_TYPE);
                }}
                label={selectedGraphType}
                sx={{ backgroundColor: "lightgray" }}
              >
                {possibleGraphTypes?.map((x) => (
                  <MenuItem value={x}>{x}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddGraph}>
          Lägg till graf
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GraphAddModal;
