import MapProps from "./Map.types";
import {
  GeolocateControl,
  Map as MapMapbox,
  MapRef,
  Marker,
  MarkerProps,
  NavigationControl,
  Popup
} from "react-map-gl";
import mapboxgl, { MapLayerMouseEvent } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MenuItem,
  Menu,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import {
  Check,
  Close,
  Delete,
  FitScreen,
  Layers,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import { useImmer } from "use-immer";
import { TOOLMODE } from "enums";
import QuestionsList from "components/QuestionsList";
import { nanoid } from "nanoid";
import { MapMarker } from "pages/SurveyPage/SurveyPage.types";
import "./Map.css";

const mapStyles = [
  { label: "Vägar", value: "mapbox://styles/mapbox/streets-v11" },
  { label: "Utomhus", value: "mapbox://styles/mapbox/outdoors-v12" },
  { label: "Satellit", value: "mapbox://styles/mapbox/satellite-v9" },
  {
    label: "Satellit vägar",
    value: "mapbox://styles/mapbox/satellite-streets-v12"
  },
  { label: "Mörk", value: "mapbox://styles/mapbox/dark-v11" },
  { label: "Ljus", value: "mapbox://styles/mapbox/light-v11" }
];

const token =
  "pk.eyJ1IjoiaW5vZGlubyIsImEiOiJjbG5mNWxuN3UwajY4MmtvaHh0dmE1dnBzIn0.3rvwbu7fkYOM9jRMxl7kwA";

const Map = (props: MapProps) => {
  const {
    toolMode,
    settings,
    showSurvey,
    onClickShowSurvey,
    onClickExitMarkerPlaceholder,
    onClickConfirmNewMarker,
    onClickConfirmUpdateMarker,
    onClickDeleteMarker,
    questions,
    markers,
    markerPlaceholderColor
  } = props;

  const map = useRef<MapRef>(null);
  const marker = useRef<mapboxgl.Marker>(null);

  const layersButton = useRef(null);

  const [openLayersMeny, setOpenLayersMeny] = useState(false);
  const [openQuestionsModal, setOpenQuestionsModal] = useState(false);
  const [mapStyle, setMapStyle] = useState(mapStyles[0].value);
  const [viewState, setViewState] = useImmer({
    longitude: 11.973347,
    latitude: 57.707049,
    zoom: 12,
    bearing: 0,
    pitch: 0
  });

  const [placeholderMarker, setPlaceholderMarker] = useState<
    MarkerProps | undefined
  >(undefined);
  const [selectedMarker, setSelectedMarker] = useImmer<MapMarker | undefined>(
    undefined
  );

  const [markerAnswers, setMarkerAnswers] = useImmer<{ [id: string]: any }>({});
  const [showPopup, setShowPopup] = useState(false);

  const handleOnMarkerClick = useCallback(
    (marker: MapMarker) => {
      setMarkerAnswers(marker.answers);
      setOpenQuestionsModal(true);
      setSelectedMarker(marker);
    },
    [setMarkerAnswers, setSelectedMarker]
  );

  const markerComponents = useMemo(
    () =>
      markers?.map((marker) => (
        <Marker
          key={marker.id}
          longitude={marker.longitude}
          latitude={marker.latitude}
          color={marker.color}
          onClick={() => handleOnMarkerClick(marker)}
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              padding: "6px",
              backgroundColor: marker.color,
              opacity: 0.8,
              borderRadius: "50%"
            }}
          >
            <img
              src="/logo.jpeg"
              alt="markerimage"
              width="100%"
              height="100%"
              style={{ borderRadius: "50%" }}
            />
          </div>
        </Marker>
      )),
    [handleOnMarkerClick, markers]
  );

  const handleOnCloseLayersMenu = () => {
    setOpenLayersMeny(false);
  };

  const handleOnClick = (evt: MapLayerMouseEvent) => {
    if (toolMode === TOOLMODE.MAP_MARKER) {
      setShowPopup(true);
      setPlaceholderMarker({
        longitude: evt.lngLat.lng,
        latitude: evt.lngLat.lat
      });
    }
  };

  const handleOnClickCheck = () => {
    setOpenQuestionsModal(true);
  };

  useEffect(() => {
    if (settings) {
      setViewState((draft) => {
        draft.zoom = settings.zoom;
        draft.latitude = settings.latitude;
        draft.longitude = settings.longitude;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    map.current?.resize();
  }, [showSurvey]);

  useEffect(() => {
    if (!toolMode) {
      setShowPopup(false);
      setPlaceholderMarker(undefined);
    }
  }, [toolMode]);

  return (
    <Stack width="100%" height="100%" position="relative">
      <MapMapbox
        reuseMaps
        ref={map}
        mapLib={mapboxgl}
        mapboxAccessToken={token}
        style={{ flex: 1 }}
        mapStyle={mapStyle}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        onClick={handleOnClick}
      >
        <GeolocateControl />
        <NavigationControl />
        {toolMode === TOOLMODE.MAP_MARKER && placeholderMarker && (
          <>
            <Marker
              ref={marker}
              draggable
              color={markerPlaceholderColor}
              {...placeholderMarker}
              onDragEnd={(e) => {
                setPlaceholderMarker({
                  longitude: e.lngLat.lng,
                  latitude: e.lngLat.lat
                });
                setShowPopup(true);
              }}
              onDragStart={() => setShowPopup(false)}
            />
            {showPopup && (
              <Popup
                {...placeholderMarker}
                anchor="top"
                offset={10}
                closeButton={false}
                closeOnClick={false}
                style={{}}
              >
                <Stack
                  padding={0.4}
                  bgcolor="rgba(255, 255, 255, 0.9)"
                  spacing={1}
                  direction="row"
                  borderRadius={2}
                >
                  <ButtonBase onClick={handleOnClickCheck}>
                    <Check sx={{ color: "green" }} />
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => {
                      setShowPopup(false);
                      setPlaceholderMarker(undefined);
                      onClickExitMarkerPlaceholder &&
                        onClickExitMarkerPlaceholder();
                      setMarkerAnswers({});
                    }}
                  >
                    <Close sx={{ color: "red" }} />
                  </ButtonBase>
                </Stack>
              </Popup>
            )}
          </>
        )}
        {markerComponents}
      </MapMapbox>
      <Stack
        direction="row"
        position="absolute"
        left={"10px"}
        top={"10px"}
        spacing={1}
      >
        <ButtonBase onClick={onClickShowSurvey}>
          {showSurvey ? (
            <Visibility
              sx={{
                bgcolor: "white",
                padding: 0.4,
                borderRadius: "4px",
                color: "black",
                outline: "1px solid lightgray"
              }}
            />
          ) : (
            <VisibilityOff
              sx={{
                bgcolor: "white",
                padding: 0.4,
                borderRadius: "4px",
                color: "black",
                outline: "1px solid lightgray"
              }}
            />
          )}
        </ButtonBase>
      </Stack>
      <Stack
        direction="row"
        position="absolute"
        right={"50px"}
        top={"10px"}
        spacing={1}
      >
        <ButtonBase
          onClick={() => {
            if (settings) {
              setViewState((draft) => {
                draft.latitude = settings.latitude;
                draft.longitude = settings.longitude;
                draft.zoom = settings.zoom;
                draft.bearing = 0;
                draft.pitch = 0;
              });
            }
          }}
        >
          <FitScreen
            sx={{
              bgcolor: "white",
              padding: 0.4,
              borderRadius: "4px",
              color: "black",
              outline: "1px solid lightgray"
            }}
          />
        </ButtonBase>
        <ButtonBase onClick={() => setOpenLayersMeny(true)}>
          <Layers
            ref={layersButton}
            sx={{
              bgcolor: "white",
              padding: 0.4,
              borderRadius: "4px",
              color: "black",
              outline: "1px solid lightgray"
            }}
          />
        </ButtonBase>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={layersButton.current}
        open={openLayersMeny}
        onClose={handleOnCloseLayersMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {mapStyles.map((x) => (
          <MenuItem
            onClick={() => {
              setMapStyle(x.value);
              handleOnCloseLayersMenu();
            }}
            key={x.value}
          >
            {x.label}
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        onClose={() => {
          setSelectedMarker(undefined);
          setOpenQuestionsModal(false);
        }}
        open={openQuestionsModal}
        PaperProps={{
          sx: {
            color: "white",
            backgroundColor: "#1c2c2b"
          }
        }}
      >
        <DialogTitle>Frågor</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <QuestionsList
              questions={questions}
              answers={markerAnswers}
              onChangeInputQuestion={(id, value) => {
                setMarkerAnswers((draft) => {
                  draft[id] = value;
                });
              }}
              onChangeDropdownQuestion={(id, value) => {
                setMarkerAnswers((draft) => {
                  draft[id] = value;
                });
              }}
              onChangeRadioQuestion={(id, value) => {
                setMarkerAnswers((draft) => {
                  draft[id] = value;
                });
              }}
              onChangeRadioMultiQuestion={(id, value) => {
                setMarkerAnswers((draft) => {
                  if (draft[id]) {
                    if (!draft[id].includes(value)) {
                      draft[id] = [...(draft[id] || []), value];
                    } else {
                      draft[id] = (draft[id] || []).filter(
                        (x: string) => x !== value
                      );
                    }
                  } else {
                    draft[id] = [value];
                  }
                });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            padding={2}
          >
            <Button
              sx={{ color: "black", backgroundColor: "white" }}
              onClick={() => {
                setSelectedMarker(undefined);
                setOpenQuestionsModal(false);
              }}
            >
              <Close />
            </Button>
            <Stack direction="row" spacing={2}>
              {selectedMarker && (
                <Button
                  onClick={() => {
                    setOpenQuestionsModal(false);
                    if (onClickDeleteMarker && selectedMarker) {
                      onClickDeleteMarker(selectedMarker.id);
                    }

                    setMarkerAnswers({});
                    setSelectedMarker(undefined);
                  }}
                  sx={{ color: "#a05858", backgroundColor: "white" }}
                >
                  <Delete />
                </Button>
              )}
              <Button
                onClick={() => {
                  setOpenQuestionsModal(false);
                  if (onClickConfirmNewMarker && marker.current) {
                    onClickConfirmNewMarker(nanoid(), markerAnswers, {
                      latitude: marker.current.getLngLat().lat,
                      longitude: marker.current.getLngLat().lng
                    });
                  } else if (onClickConfirmUpdateMarker && selectedMarker) {
                    onClickConfirmUpdateMarker(
                      selectedMarker.id,
                      markerAnswers
                    );
                  }
                  setMarkerAnswers({});
                  setSelectedMarker(undefined);
                }}
                sx={{ color: "#58a05f", backgroundColor: "white" }}
              >
                <Check />
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Map;
