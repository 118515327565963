import ButtonProps from "./Button.types";
import ButtonMui from "@mui/material/Button";

const Button = (props: ButtonProps) => {
  const { text = "button", onClick, sx } = props;

  return (
    <ButtonMui onClick={onClick} variant="contained" color="primary" sx={sx}>
      {text}
    </ButtonMui>
  );
};

export default Button;
