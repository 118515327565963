import { Survey } from "interfaces";
import { Data, GRAPH_TYPE } from "./DataVisualizer/DataVisualizer.types";

interface AnalyzeViewPageProps {
  surveyTitle?: string;
  surveyId?: string;
  surveyMeta?: Survey["meta"];
  nAnswerPoints?: number;
  nRespondents?: number;
  nMapMarkers?: number;
  answerDateData?: { date: number; value: number }[];
  onClickExport?: () => void;
  exportAnswers?: any;
  flatExport?: boolean;
  onChangeFlatExport?: () => void;
  onClickSignOut?: () => void;
  onClickAddDataVisualizer?: () => void;
  onClickFilter?: () => void;
  filterCount?: number;
  onClickDeleteDataVisualizer?: (id: string) => void;
  onClickResizeDataVisualizer?: (id: string) => void;
  dataVisualizers?: {
    questionId: string;
    questionLabel: string;
    visualisationType: GRAPH_TYPE;
    data: Data[];
    visualizationSize?: number;
  }[];
}

export enum FILTER_COMPARISON {
  IS = "is",
  IS_NOT = "is not",
  CONTAINS = "contains"
  // GREATER = "greater",
  // LESS = "less",
}

interface AnalyzePageProps {}

export type { AnalyzeViewPageProps };
export default AnalyzePageProps;
