import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useCallback, useEffect, useRef } from "react";
import { Unsubscribe } from "firebase/auth";
import SessionManagerProps from "./SessionManager.types";
import { fire } from "services";

const SessionManager = (props: SessionManagerProps): ReactElement => {
  /** Hook: location */
  const location = useLocation();

  /** Hook: navigation */
  const navigate = useNavigate();

  /** Ref: unsubscribe function for onAuthStateChanged */
  /** Ref: unsubscribe function for onUpdateUserDoc */
  // const unSubOnUpdateUserDoc = useRef<void | Unsubscribe>();
  // /** Ref: unsubscribe function for onUpdateCompanyDoc */
  // const unSubOnUpdateCompanyDoc = useRef<void | Unsubscribe>();
  // /** Ref: unsubscribe function for onUpdateCompanyUsersDoc */
  // const unSubOnUpdateCompanyUserDocs = useRef<void | Unsubscribe>();

  /** Callback: error handler */
  const handleOnError = useCallback(
    (error?: any) => {
      // clearUser();
      // if (unSubOnUpdateUserDoc.current) unSubOnUpdateUserDoc.current();
      // if (unSubOnUpdateCompanyDoc.current) unSubOnUpdateCompanyDoc.current();
      // if (unSubOnUpdateCompanyUserDocs.current)
      //   unSubOnUpdateCompanyUserDocs.current();
      // setLoading(false);
      fire.signOut();
      navigate("/login");
    },
    [navigate]
  );

  // const handleOnUpdateCompanyDocSuccess = useCallback(
  //   (companyDoc: CompanyDoc, companyId: string) => {
  //     const { name } = companyDoc;
  //     setCompanyName(name);
  //     // storage.getCompanyLogoDownloadUrl({
  //     //   companyId,
  //     //   onSuccess: (logoUrl) => {
  //     //     setCompanyLogoUrl(logoUrl);
  //     //   },
  //     //   onError: () => {},
  //     // });
  //     // setLoading(false);
  //   },
  //   [setCompanyName]
  // );

  // const setUserStateProperties = useCallback(
  //   (userDoc: UserDoc, userId: string) => {
  //     const {
  //       email,
  //       firstName,
  //       lastName,
  //       companyId,
  //       appPreferences,
  //       scaffoldPreferences,
  //       accountType,
  //       expires,
  //       role
  //     } = userDoc;

  //     setUserEmail(email);
  //     setUserId(userId);
  //     setUserFirstName(firstName);
  //     setUserLastName(lastName);
  //     setCompanyId(companyId);
  //     setUserAppPreferences(appPreferences);
  //     setUserScaffoldPreferences(scaffoldPreferences);
  //     setUserAccountType(accountType);
  //     setUserExpires(expires.toDate());
  //     setUserRole(role);
  //   },
  //   [
  //     setUserEmail,
  //     setUserId,
  //     setUserFirstName,
  //     setUserLastName,
  //     setCompanyId,
  //     setUserAppPreferences,
  //     setUserScaffoldPreferences,
  //     setUserAccountType,
  //     setUserExpires,
  //     setUserRole
  //   ]
  // );

  // const handleOnEmailNotVerified = useCallback(() => {
  //   setSnackbar({ text: "Please verify your email address", type: "error" });
  //   authentication.signOut();
  // }, [setSnackbar]);

  // const handleOnUpdateUserDocSuccess = useCallback(
  //   (userDoc: UserDoc, userId: string) => {
  //     const { companyId, active } = userDoc;

  //     if (!active) return handleOnError();
  //     setUserStateProperties(userDoc, userId);

  //     /** Setup company document listener and provide onSuccess function */
  //     unSubOnUpdateCompanyDoc.current = database.onUpdateCompanyDoc({
  //       companyId,
  //       onSuccess: (companyDoc) =>
  //         handleOnUpdateCompanyDocSuccess(companyDoc, companyId),
  //       onError: handleOnError
  //     });

  //     /** Setup company document listener and provide onSuccess function */
  //     unSubOnUpdateCompanyUserDocs.current = database.onUpdateCompanyUserDocs({
  //       companyId,
  //       onSuccess: (userDocs) => {
  //         setCompanyUsers(userDocs);
  //       },
  //       onError: handleOnError
  //     });
  //   },
  //   [
  //     handleOnError,
  //     handleOnUpdateCompanyDocSuccess,
  //     setCompanyUsers,
  //     setUserStateProperties
  //   ]
  // );

  useEffect(() => {
    /** Setup listener on auth object and provide onUpdate function */
    fire.setupAuthObserver(
      (authUser) => {
        if (!authUser) {
          handleOnError();
          return;
        }

        // const { uid, emailVerified } = authUser;
        // if (!emailVerified) {
        //   handleOnEmailNotVerified();
        //   return;
        // }

        /** Setup user document listener and provide onSuccess function */
        // unSubOnUpdateUserDoc.current = database.onUpdateUserDoc({
        //   uid,
        //   onSuccess: (userDoc) => handleOnUpdateUserDocSuccess(userDoc, uid),
        //   onError: handleOnError
        // });

        /** Navigate to /home if current route is /login
         * otherwise continue with specified route
         */
        if (location.pathname === "/login") navigate("/design");
      },
      () => {}
    );
  }, [handleOnError, location.pathname, navigate]);

  return <Outlet />;
};

export default SessionManager;
