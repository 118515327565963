import { memo } from "react";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import RadioMapQuestionProps from "./RadioMapQuestion.types";
import _ from "lodash";

const equalValue = (
  prevProps: RadioMapQuestionProps,
  nextProps: RadioMapQuestionProps
) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.name === nextProps.name &&
    _.isEqual(prevProps.options, nextProps.options)
  );
};

const RadioMapQuestion = memo((props: RadioMapQuestionProps) => {
  const { name, options = [], id, onChange } = props;

  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          color: "white",
          backgroundColor: "#193e3c",
          position: "relative"
        }}
      >
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            {name}
          </Typography>
          <FormControl>
            <RadioGroup
              value={id}
              onChange={(e) => {
                const option = options.find((x) => x.id === e.target.value);

                if (option && onChange) onChange(e.target.value, option.map);
              }}
              sx={{ width: "100%" }}
            >
              {options?.map((x) => (
                <FormControlLabel
                  key={x.id}
                  control={
                    <Radio
                      sx={{
                        color: "#ffffff",
                        "&.Mui-checked": {
                          color: "#4bc0b8"
                        }
                      }}
                    />
                  }
                  label={x.label}
                  value={x.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack
          position="absolute"
          bottom={10}
          right={10}
          sx={{ opacity: 1, color: "green" }}
        >
          {id && `✓`}
        </Stack>
      </CardContent>
    </Card>
  );
}, equalValue);

export default RadioMapQuestion;
