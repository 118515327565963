import { useEffect, useState } from "react";
import { fire } from "./services";
import { Backdrop, CircularProgress } from "@mui/material";
import RouterManager from "./components/RouterManager";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fire.setupAuthObserver(
      (user) => {
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      fire.removeAuthObserver();
    };
  }, []);

  if (isLoading)
    return (
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    );

  return <RouterManager />;
};

export default App;
