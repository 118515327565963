import Card from "@mui/material/Card";
import InputQuestionProps from "./InputQuestion.types";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TextField, styled } from "@mui/material";
import { memo } from "react";

const equalValue = (
  prevProps: InputQuestionProps,
  nextProps: InputQuestionProps
) => {
  return prevProps.id === nextProps.id && prevProps.name === nextProps.name;
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7"
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C"
    }
  }
});

const InputQuestion = memo((props: InputQuestionProps) => {
  const { name, id, onChange } = props;

  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          color: "white",
          backgroundColor: "#193e3c",
          position: "relative"
        }}
      >
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            {name}
          </Typography>
          <CssTextField
            multiline
            maxRows={6}
            inputProps={{ sx: { color: "white" } }}
            sx={{ borderColor: "red" }}
            onChange={(e) => onChange && onChange(e.target.value)}
            value={id}
          />
        </Stack>
        <Stack
          position="absolute"
          bottom={10}
          right={10}
          sx={{ opacity: 1, color: "green" }}
        >
          {id && `✓`}
        </Stack>
      </CardContent>
    </Card>
  );
}, equalValue);

export default InputQuestion;
