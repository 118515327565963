import { memo } from "react";
import MapMarkerQuestionProps from "./MapMarkerQuestion.types";
import {
  Badge,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography
} from "@mui/material";

const MapMarkerQuestion = memo((props: MapMarkerQuestionProps) => {
  const { name, options, onClick, nAnswers } = props;

  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          color: "white",
          backgroundColor: "#193e3c",
          position: "relative"
        }}
      >
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            {name}
          </Typography>
          <Stack direction="row" spacing={1}>
            {options?.map((x) => (
              <Badge color="primary" badgeContent={nAnswers}>
                <Chip
                  key={x.id}
                  size="medium"
                  label={x.label}
                  onClick={() => {
                    onClick && onClick(x.id);
                  }}
                  sx={{
                    backgroundColor: x.backgroundColor ?? "#497976",
                    color: x.textColor ?? "white",
                    "&:hover": {
                      backgroundColor: "#000000"
                    },
                    fontWeight: "bold"
                  }}
                />
              </Badge>
            ))}
          </Stack>
        </Stack>
        <Stack
          position="absolute"
          bottom={10}
          right={10}
          sx={{ opacity: 1, color: "green" }}
        >
          {nAnswers && nAnswers > 0 ? `✓` : undefined}
        </Stack>
      </CardContent>
    </Card>
  );
});

export default MapMarkerQuestion;
