import {
  FirebaseApp,
  FirebaseError,
  FirebaseOptions,
  initializeApp
} from "firebase/app";
import {
  Firestore,
  addDoc,
  collection as collectionFire,
  connectFirestoreEmulator,
  Timestamp,
  query,
  where,
  onSnapshot,
  initializeFirestore,
  doc,
  getDoc as getDocFirestore
} from "firebase/firestore";
import {
  Auth,
  ErrorFn,
  NextOrObserver,
  Unsubscribe,
  User,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword
} from "firebase/auth";
import {
  FirebaseStorage,
  connectStorageEmulator,
  getStorage
} from "firebase/storage";
import { nanoid } from "nanoid";
import { Survey } from "interfaces";

class Fire {
  private _app: FirebaseApp;
  private _db: Firestore;
  private _auth: Auth;
  private _storage: FirebaseStorage;
  private _authObserverUnsubscribe?: Unsubscribe;

  constructor(config: FirebaseOptions, environment?: string) {
    this._app = initializeApp(config);

    this._db = initializeFirestore(this._app, {
      experimentalAutoDetectLongPolling: true
    });
    this._auth = getAuth(this._app);
    this._storage = getStorage(this._app);

    if (environment === "development") this.connectWithEmulator();
  }

  public signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(this._auth, email, password);
  };

  public signOut = () => {
    this.removeAuthObserver();

    return this._auth.signOut();
  };

  private addDoc = (coll: string, data: any) => {
    return addDoc(collectionFire(this._db, coll), data);
  };

  private getDoc = (coll: string, id: string) => {
    return getDocFirestore(doc(this._db, coll, id));
  };

  public getUser = () => {
    return this._auth.currentUser;
  };

  public setupAuthObserver = (
    observer: NextOrObserver<User>,
    error: ErrorFn
  ) => {
    this._authObserverUnsubscribe = onAuthStateChanged(
      this._auth,
      observer,
      error
    );
  };

  public removeAuthObserver = () => {
    if (this._authObserverUnsubscribe) this._authObserverUnsubscribe();
  };

  public createSurvey = (props: {
    uid: string;
    name: string;
    description: string;
  }) => {
    const { uid, name, description } = props;

    const survey: Omit<Survey, "fireId"> = {
      id: nanoid(),
      created: Timestamp.now(),
      createdByUid: uid,
      edited: Timestamp.now(),
      editedByUid: uid,
      name: name,
      description: description,
      pages: []
    };

    return this.addDoc("surveys", survey);
  };

  public sendAnswer = (props: {
    surveyFireId: string;
    surveyId: string;
    answers: { [key: string]: string };
    mapMarkers: {
      id: string;
      latitude: number;
      longitude: number;
      answers: { [id: string]: any };
      color?: string;
    }[];
  }) => {
    const { surveyId, answers, surveyFireId, mapMarkers } = props;

    const answer = {
      id: nanoid(),
      surveyId,
      surveyFireId,
      answers,
      mapMarkers,
      created: Timestamp.now()
    };

    return this.addDoc("answers", answer);
  };

  public subscribeToSurvey = (props: {
    shortId: string;
    id: string;
    onSuccess: (doc: Survey) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { shortId, id, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "surveys"),
      where("id", "==", `${shortId}/${id}`),
      where("published", "==", true)
    );

    return onSnapshot(
      q,
      (snap) => {
        let entry: Survey | undefined = undefined;
        if (snap.size === 1) {
          snap.forEach((doc) => {
            entry = { ...doc.data(), fireId: doc.id } as Survey;
          });
        }

        if (entry) {
          onSuccess(entry);
        } else {
          onError(new Error("Document not found") as FirebaseError);
        }
      },
      (error) => {
        onError(error);
      }
    );
  };

  public subscribeToSurveys = (props: {
    uid: string;
    onSuccess: (docs: any[]) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { uid, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "surveys"),
      where("createdByUid", "==", uid)
    );

    return onSnapshot(
      q,
      (snap) => {
        const entries: any[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data();
            entries.push({ ...data, fireId: doc.id });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  public subscribeToAnswers = (props: {
    id: string;
    shortId: string;
    onSuccess: (docs: any[]) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { id, shortId, onSuccess, onError } = props;

    let q = query(
      collectionFire(this._db, "answers"),
      where("surveyId", "==", `${shortId}/${id}`)
    );

    return onSnapshot(
      q,
      (snap) => {
        const entries: any[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data();
            entries.push({ ...data, fireId: doc.id });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  public getSurvey = (props: { id: string }) => {
    const { id } = props;

    return this.getDoc("surveys", id).then((doc) => {
      if (doc.exists()) {
        return doc.data() as Survey;
      } else {
        throw new Error("Document not found");
      }
    });
  };

  private connectWithEmulator = () => {
    connectFirestoreEmulator(this._db, "0.0.0.0", 8080);
    connectAuthEmulator(this._auth, "http://0.0.0.0:9099", {
      disableWarnings: true
    });
    connectStorageEmulator(this._storage, "http://0.0.0.0", 9199);
  };
}

export default Fire;
