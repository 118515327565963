import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QUESTION_TYPE } from "enums";
import { useCallback, useMemo, useState } from "react";
import { FILTER_COMPARISON } from "../AnalyzePage.types";
import FilterModalProps, { Filter } from "./FilterModal.types";
import { nanoid } from "nanoid";

const NOT_SUPPORTED_QUESTION_TYPES = [
  QUESTION_TYPE.TEXT,
  QUESTION_TYPE.MAP_LINE,
  QUESTION_TYPE.MAP_MARKER,
  QUESTION_TYPE.INPUT
];

export const FILTER_COMPARISON_OPTIONS = [
  { value: FILTER_COMPARISON.IS, label: "Är" },
  { value: FILTER_COMPARISON.IS_NOT, label: "Är inte" },
  { value: FILTER_COMPARISON.CONTAINS, label: "Innehåller" }
];
const AppliedFilters = (props: {
  filters: Filter[];
  onRemoveFilter?: (filter: Filter) => void;
}) => {
  const { filters, onRemoveFilter } = props;

  const handleOnRemoveFilter = useCallback(
    (filter: Filter) => {
      if (!onRemoveFilter) return;
      onRemoveFilter(filter);
    },
    [onRemoveFilter]
  );

  if (filters.length === 0) return null;
  return (
    <Stack spacing={2}>
      <Typography fontSize={18} fontWeight={"bold"}>
        {"Applicerade filter:"}
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Typography fontSize={18} fontWeight={"bold"}>
            Fråga:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontSize={18} fontWeight={"bold"}>
            Jämförelse:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={18} fontWeight={"bold"}>
            Svar:
          </Typography>
        </Grid>
        {filters.map((f) => (
          <>
            <Grid item xs={4}>
              <Typography>{f.questionLabel}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {
                  FILTER_COMPARISON_OPTIONS.find(
                    (o) => o.value === f.filterComparison
                  )?.label
                }
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography> {f.filterValueLabel} </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" onClick={() => handleOnRemoveFilter(f)}>
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </>
        ))}
      </Grid>
    </Stack>
  );
};

const FilterModal = (props: FilterModalProps) => {
  const { isOpen, onClose, filters, onClickAddFilter, onRemoveFilter, survey } =
    props;

  const [selectedFilterValue, setSelectedFilterValue] = useState<string | null>(
    null
  );
  const [selectedFilterId, setSelectedFilterId] = useState<string | null>(null);
  const [selectedFilterComparison, setSelectedFilterComparison] =
    useState<FILTER_COMPARISON | null>(null);
  const [selectedFilterQuestionId, setSelectedFilterQuestionId] = useState<
    string | null
  >(null);

  const questions = useMemo(() => {
    if (!survey) return [];
    return survey.pages
      .map((page, pageIdx) =>
        page.questions.map((question) => ({ ...question, pageIdx }))
      )
      .flat()
      .filter((q) => !NOT_SUPPORTED_QUESTION_TYPES.includes(q.type));
  }, [survey]);

  const selectedQuestion = useMemo(() => {
    if (!selectedFilterQuestionId || !survey) return;
    const { pages } = survey;
    const questions = pages
      .map((page, pageIdx) =>
        page.questions.map((question) => ({ ...question, pageIdx }))
      )
      .flat();

    const newSelectedQuestion = questions.find(
      (q) => q.id === selectedFilterQuestionId
    );
    return newSelectedQuestion;
  }, [selectedFilterQuestionId, survey]);

  const selectedQuestionOptions = useMemo(() => {
    if (!selectedQuestion) return [];

    const { type } = selectedQuestion;
    if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN ||
      type === QUESTION_TYPE.RADIO_MULTI
    ) {
      return selectedQuestion.options.map((option) => ({
        label: option.label,
        value: nanoid(),
        name: option.id
      }));
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      return selectedQuestion.conditions
        .map((option) => option.options)
        .flat()
        .map((option) => ({
          label: option.label,
          value: nanoid(),
          name: option.id
        }));
    }
    return [];
  }, [selectedQuestion]);

  const handleOnChangeSelectedFilterQuestionId = useCallback((id: string) => {
    setSelectedFilterQuestionId(id);
  }, []);
  const resetStates = useCallback(() => {
    setSelectedFilterQuestionId(null);
    setSelectedFilterComparison(null);
    setSelectedFilterValue(null);
    setSelectedFilterId(null);
  }, []);

  const handleOnClickAddFilter = useCallback(() => {
    if (!onClickAddFilter) return;
    if (
      !selectedFilterQuestionId ||
      !selectedFilterComparison ||
      !selectedFilterValue ||
      !selectedQuestion
    )
      return;

    const filter = {
      questionId: selectedFilterQuestionId,
      filterComparison: selectedFilterComparison,
      filterValue: selectedFilterValue,
      filterValueLabel:
        selectedQuestionOptions.find((o) => o.value === selectedFilterId)
          ?.label ?? "",
      questionLabel: selectedQuestion.name
    };
    onClickAddFilter(filter);
    resetStates();
  }, [
    onClickAddFilter,
    resetStates,
    selectedFilterComparison,
    selectedFilterId,
    selectedFilterQuestionId,
    selectedFilterValue,
    selectedQuestion,
    selectedQuestionOptions
  ]);

  const handleOnClose = useCallback(() => {
    onClose();
    resetStates();
  }, [onClose, resetStates]);

  return (
    <Dialog
      className="filter-modal"
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={2}>
          <AppliedFilters filters={filters} onRemoveFilter={onRemoveFilter} />
          <Typography>{"Välj fråga att filtrera på"}</Typography>
          <FormControl fullWidth>
            <InputLabel>{"Filter"}</InputLabel>
            <Select
              value={selectedFilterQuestionId}
              onChange={(e) => {
                const { value } = e.target;
                handleOnChangeSelectedFilterQuestionId(value as string);
              }}
              label={
                questions.find((d) => d.id === selectedFilterQuestionId)?.name
              }
              sx={{ backgroundColor: "lightgray" }}
            >
              {questions.map((x) => (
                <MenuItem value={x.id}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedFilterQuestionId && (
            <FormControl fullWidth>
              <InputLabel>{"Filter jämförelse"}</InputLabel>
              <Select
                value={selectedFilterComparison}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedFilterComparison(value as FILTER_COMPARISON);
                }}
                label={
                  FILTER_COMPARISON_OPTIONS.find(
                    (o) => o.value === selectedFilterComparison
                  )?.label
                }
                sx={{ backgroundColor: "lightgray" }}
              >
                {FILTER_COMPARISON_OPTIONS.map((x) => (
                  <MenuItem value={x.value}>{x.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedQuestionOptions.length > 0 && (
            <FormControl fullWidth>
              <InputLabel>{"Värde"}</InputLabel>
              <Select
                value={selectedFilterId}
                onChange={(e) => {
                  const { value } = e.target;

                  setSelectedFilterId(value as string);

                  const selectedOption = selectedQuestionOptions.find(
                    (x) => x.value === value
                  )?.name;

                  if (selectedOption) setSelectedFilterValue(selectedOption);
                }}
                label={
                  selectedQuestionOptions.find(
                    (o) => o.value === selectedFilterValue
                  )?.label
                }
                sx={{ backgroundColor: "lightgray" }}
              >
                {selectedQuestionOptions.map((x) => (
                  <MenuItem value={x.value}>{x.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleOnClose}>
          Avbryt
        </Button>
        <Button size="small" onClick={handleOnClickAddFilter}>
          Lägg till filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
