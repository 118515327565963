export enum GRAPH_TYPE {
  BAR = "stapel",
  LINE = "linje",
  PIE = "cirkel"
}
export interface Data {
  label: string;
  value: number;
}

export interface DataVisualizerProps {
  data: Data[];
  type: GRAPH_TYPE;
  graphTitle: string;
  graphId?: string;
  onClickDelete?: (id: string) => void;
  onClickResize?: (id: string) => void;
}
export interface DataVisualizerViewProps {
  graphTitle: string;
  graphId?: string;
  data: Data[];
  type: GRAPH_TYPE;
  onClickDelete: () => void;
  onClickResize: () => void;
}
