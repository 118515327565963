import Stack from "@mui/material/Stack";
import LoginPageViewProps from "./LoginPage.view.types";
import { Button, TextField, Typography } from "@mui/material";

const LoginPageView = (props: LoginPageViewProps) => {
  const { email, password, onClickLogin, onChangeEmail, onChangePassword } =
    props;

  return (
    <Stack
      width="100dvw"
      height="100dvh"
      direction="row"
      justifyContent="center"
    >
      <Stack alignItems="center" justifyContent="center" spacing={4}>
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ userSelect: "none" }}
        >
          <img
            src="/logo.jpeg"
            alt="background"
            width="80px"
            style={{ borderRadius: "10px" }}
          />
          <Typography color="white" fontSize="24px">
            MapX
          </Typography>
        </Stack>
        <Stack height="200px" justifyContent="center" alignItems="center">
          <Stack spacing={4} padding="20px" minWidth="400px">
            <TextField
              placeholder="Email"
              type="email"
              variant="standard"
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              InputProps={{
                sx: { color: "white" }
              }}
            />
            <TextField
              placeholder="Password"
              type="password"
              variant="standard"
              value={password}
              onChange={(e) => onChangePassword(e.target.value)}
              sx={{ color: "White" }}
              InputProps={{
                sx: { color: "white" }
              }}
            />
            <Button variant="contained" onClick={onClickLogin}>
              Login
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginPageView;
