import Stack from "@mui/material/Stack";
import { AnalyzeViewPageProps } from "./AnalyzePage.types";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { Badge, IconButton, Paper } from "@mui/material";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { Button } from "components";
import DataVisualizer from "./DataVisualizer";
import TuneIcon from "@mui/icons-material/Tune";
const dateFormatter = (date: Date) => {
  return format(new Date(date), "dd-MM-yy");
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: "1px 10px 1px 10px",
          backgroundColor: "rgba(0,0,0,0.5)",
          borderRadius: "4px"
        }}
      >
        <p>{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const AnalyzeViewPage = (props: AnalyzeViewPageProps) => {
  const {
    surveyTitle,
    surveyId,
    nAnswerPoints,
    nRespondents,
    nMapMarkers,
    answerDateData,
    onClickExport,
    exportAnswers,
    // flatExport,
    // onChangeFlatExport,
    onClickSignOut,
    onClickAddDataVisualizer,
    onClickDeleteDataVisualizer,
    onClickResizeDataVisualizer,
    dataVisualizers,
    filterCount,
    onClickFilter
  } = props;

  return (
    <Stack direction="row" width="100dvw" height="100dvh">
      <Stack
        minWidth="200px"
        height="100%"
        bgcolor="#dcd9dd"
        position="relative"
      >
        <Stack
          width="100%"
          height="160px"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src="/logo.jpeg"
            alt="logo"
            width="52px"
            height="52px"
            style={{ borderRadius: "12px" }}
          />
          <Typography variant="h6" color="#36494b">
            MapX (A)
          </Typography>
        </Stack>
        <Stack
          width="100%"
          height="120px"
          bgcolor="#dcd9dd"
          justifyContent="center"
          alignItems="center"
        >
          <Button text="Logga ut" onClick={onClickSignOut} />
        </Stack>
        <Stack width="100%" height="100%" bgcolor="#dcd9dd" />
      </Stack>
      <Stack flex={1} width="100%" height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          margin={"20px"}
        >
          <Stack alignItems="left" height="40px">
            <Typography color={"white"}>{surveyTitle}</Typography>
            <Typography color="lightgray" fontSize="12px">
              {surveyId}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={8}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              padding={2}
              bgcolor="rgba(255,255,255,0.1)"
              borderRadius="12px"
            >
              <Button
                text="Lägg till graf"
                onClick={onClickAddDataVisualizer}
                sx={{ backgroundColor: "white", color: "black" }}
              />
              <IconButton onClick={onClickFilter}>
                <Badge badgeContent={filterCount} color="primary">
                  <TuneIcon sx={{ color: "white" }} />
                </Badge>
              </IconButton>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              padding={2}
              bgcolor="rgba(255,255,255,0.1)"
              borderRadius="12px"
            >
              <CSVLink
                data={exportAnswers}
                onClick={onClickExport}
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "6px",
                  color: nAnswerPoints === undefined ? "lightgray" : "black"
                }}
                filename={`mapx-export_${
                  new Date().toISOString().split("T")[0]
                }_${surveyId?.split("/")[0]}-${surveyId?.split("/")[1]}`}
              >
                export
              </CSVLink>
              {/* <Typography fontSize="12px">Flat?</Typography>
              <Switch onChange={onChangeFlatExport} value={flatExport} /> */}
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack component="div" sx={{ overflowY: "scroll" }} height="100%">
          <Grid container width="100%">
            <Grid item xs={4}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white"
                }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography fontSize={60}>{nRespondents}</Typography>
                  <Typography fontSize={12}>SVAR</Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white"
                }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography fontSize={60}>{nAnswerPoints}</Typography>
                  <Typography fontSize={12}>DATAPUNKTER</Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white"
                }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography fontSize={60}>{nMapMarkers}</Typography>
                  <Typography fontSize={12}>KARTPUNKTER</Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white",
                  height: "400px"
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={answerDateData}>
                      <XAxis
                        stroke="white"
                        dataKey="date"
                        tickFormatter={dateFormatter}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <YAxis stroke="white" />
                      <Line type="monotone" dataKey="value" stroke="#ea00ff" />
                      <CartesianGrid strokeDasharray="3 3" stroke="#cccccc18" />
                    </LineChart>
                  </ResponsiveContainer>
                  <Typography fontSize={18}>SVARSFREKVENS</Typography>
                </Stack>
              </Paper>
            </Grid>
            {dataVisualizers?.map((dv, i) => {
              return (
                <Grid
                  item
                  xs={dv.visualizationSize ? dv.visualizationSize : 12}
                >
                  <DataVisualizer
                    key={i}
                    data={dv.data}
                    type={dv.visualisationType}
                    graphTitle={dv.questionLabel}
                    graphId={dv.questionId}
                    onClickDelete={onClickDeleteDataVisualizer}
                    onClickResize={onClickResizeDataVisualizer}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AnalyzeViewPage;
