import Stack from "@mui/material/Stack";
import ErrorProps from "./ErrorPage.types";
import Typography from "@mui/material/Typography";

const ErrorPage = (props: ErrorProps) => {
  return (
    <Stack
      width="100dvw"
      height="100dvh"
      direction="row"
      justifyContent="center"
    >
      <Stack alignItems="center" justifyContent="center" spacing={4}>
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ userSelect: "none" }}
        >
          <img
            src="/logo.jpeg"
            alt="background"
            width="80px"
            style={{ borderRadius: "10px" }}
          />
        </Stack>
        <Stack
          height="200px"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Stack
            spacing={2}
            padding="20px"
            minWidth="400px"
            alignItems="center"
          >
            <Typography color="white" fontSize="24px">
              Oops! Något gick snett.
            </Typography>
            <Typography color="white" fontSize="18px">
              Sidan du letar efter finns inte.
            </Typography>
          </Stack>
          <Typography
            color="lightgray"
            fontSize="12px"
            width="400px"
            align="center"
          >
            Vänligen försök igen med annat id eller ta kontakt med MapX för
            teknisk support om problemet kvarstår [ info@mapx.se ].
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ErrorPage;
