import { IconButton, Paper, Stack, Typography } from "@mui/material";
import { DataVisualizerViewProps, GRAPH_TYPE } from "./DataVisualizer.types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  Pie,
  PieChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { dateFormatter } from "./DataVisualizer.utils";
import DeleteIcon from "@mui/icons-material/Delete";
import AspectRatioICon from "@mui/icons-material/AspectRatio";

const DataVisualizerView = (props: DataVisualizerViewProps) => {
  const { data, type, graphTitle, onClickDelete, onClickResize } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        margin: 1,
        padding: 2,
        backgroundColor: "rgba(0,0,0,0)",
        outline: "1px solid white",
        color: "white",
        height: "400px"
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
          spacing={2}
        >
          <Typography fontSize={18}>{`${graphTitle}`}</Typography>
          <Stack
            bgcolor="rgba(255,255,255,0.1)"
            borderRadius="12px"
            direction="row"
            spacing={0}
          >
            <IconButton onClick={onClickResize}>
              <AspectRatioICon sx={{ color: "white" }} />
            </IconButton>
            <IconButton onClick={onClickDelete}>
              <DeleteIcon sx={{ color: "white" }} />
            </IconButton>
          </Stack>
        </Stack>
        <ResponsiveContainer width="100%" height="100%">
          {type === GRAPH_TYPE.LINE ? (
            <LineChart data={data}>
              <XAxis
                stroke="white"
                dataKey="date"
                tickFormatter={dateFormatter}
              />
              <YAxis stroke="white" />
              <Line type="monotone" dataKey="value" stroke="#ea00ff" />
              <CartesianGrid strokeDasharray="3 3" stroke="#cccccc18" />
            </LineChart>
          ) : type === GRAPH_TYPE.PIE ? (
            <PieChart>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data}
                cx="50%"
                cy="50%"
                fill="#8884d8"
                outerRadius={80}
                label={({ label, value }) => `${label} (${value})`}
              />
            </PieChart>
          ) : type === GRAPH_TYPE.BAR ? (
            <BarChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <Bar
                dataKey="value"
                fill="#8884d8"
                activeBar={<Rectangle fill="#82ca9d" stroke="blue" />}
              />
              <XAxis dataKey="label" stroke="white" />
              <Tooltip
                contentStyle={{ color: "#8884d8" }}
                cursor={{ opacity: 0.4 }}
              />
              <YAxis stroke="white" />
              <CartesianGrid strokeDasharray="3 3" stroke="#cccccc18" />
            </BarChart>
          ) : (
            <Typography fontSize={18}>{"No Data To Show"}</Typography>
          )}
        </ResponsiveContainer>
      </Stack>
    </Paper>
  );
};

export default DataVisualizerView;
