import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DropdownQuestionProps from "./DropdownQuestion.types";

const DropdownQuestion = (props: DropdownQuestionProps) => {
  const { options, id: value, onChange, name, multiple } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>{name}</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          if (onChange) {
            const { value } = e.target;
            onChange(value);
          }
        }}
        multiple={multiple}
        label={name}
        sx={{ backgroundColor: "lightgray" }}
      >
        {options?.map((x) => (
          <MenuItem value={x.id}>{x.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownQuestion;
