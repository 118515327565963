import { useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { fire } from "services";
import { Timestamp, Unsubscribe } from "firebase/firestore";
import {
  convertFireAnswers,
  getPossibleDataVisualizers,
  mapQuestionsToCsvMap,
  mapRespondentAnswers,
  sameDay
} from "./AnalyzePage.utils";
import { Survey } from "interfaces";
import AnalyzeProps, { FILTER_COMPARISON } from "./AnalyzePage.types";
import AnalyzeViewPage from "./AnalyzePage.view";
import { writeFileXLSX, utils } from "xlsx";
import { Data, GRAPH_TYPE } from "./DataVisualizer/DataVisualizer.types";
import { Filter } from "./FilterModal/FilterModal.types";
import FilterModal from "./FilterModal";
import GraphAddModal from "./GraphAddModal";

const AnalyzePage = (props: AnalyzeProps) => {
  const { id, shortId } = useParams();

  const unsubscribeAnswers = useRef<void | Unsubscribe>();
  const unsubscribeSurveys = useRef<void | Unsubscribe>();

  const [isOpenGraphAddModal, setIsOpenGraphAddModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [survey, setSurvey] = useState<Survey>();
  const [filteredRespondents, setFilteredRespondents] = useState<
    {
      answers: any;
      meta: any;
      mapMarkers: any;
    }[]
  >([]);
  const [respondents, setRespondents] = useState<
    { answers: any; meta: any; mapMarkers: any }[]
  >([]);
  const [csvRespondents, setCsvRespondents] = useState<string[][]>([]);
  const [dataVisualizers, setDataVisualizers] = useState<
    {
      questionId: string;
      questionLabel: string;
      visualisationType: GRAPH_TYPE;
      data: Data[];
      visualizationSize?: number;
    }[]
  >([]);
  const [nAnswerPoints, setNAnswerPoints] = useState(0);
  const [nMapMarkers, setNMapMarkers] = useState(0);
  const [answerDateData, setAnswerDateData] = useState<
    { date: number; value: number }[]
  >([]);
  const [splitCsvWithOptionColumns, setSplitCsvWithOptionColumns] =
    useState(false);

  const handleOnChangeFlatExport = useCallback(() => {
    setSplitCsvWithOptionColumns((prev) => !prev);
  }, []);

  const handleOnError = useCallback(() => {}, []);

  const handleOnClickSignOut = useCallback(() => {
    fire.signOut();
  }, []);

  const handleOnClickAddDataVisualizer = useCallback(() => {
    setIsOpenGraphAddModal(true);
  }, []);

  const handleOnClickFilter = useCallback(() => {
    setIsOpenFilterModal(true);
  }, []);

  const updateDataVisualizersData = useCallback(
    (filterRespondents: { answers: any; meta: any }[]) => {
      if (!survey) return;

      setDataVisualizers((prev) => {
        const updatedDataVisualizers = prev.map((dv) => {
          const updatedData = getPossibleDataVisualizers(
            survey,
            filterRespondents
          ).find((pdv) => pdv.questionId === dv.questionId)?.data;
          return { ...dv, data: updatedData ?? [] };
        });
        return updatedDataVisualizers;
      });
    },
    [survey]
  );

  const handleOnClickAddFilter = useCallback((filter: Filter) => {
    setFilters((prev) => {
      return [...prev, filter];
    });
  }, []);

  const handleOnClickRemoveFilter = useCallback((filter: Filter) => {
    setFilters((prev) => {
      return prev.filter((f) => f !== filter);
    });
  }, []);

  const handleOnCloseFilterModal = useCallback(() => {
    setIsOpenFilterModal(false);
  }, []);

  const handleOnClickAddGraph = useCallback(
    (dataVisualizer: {
      questionId: string;
      questionLabel: string;
      visualisationType: any;
      data: Data[];
    }) => {
      setDataVisualizers((prev) => {
        const lastPrev = prev[prev.length - 1];

        if (lastPrev && lastPrev.visualizationSize === 6) {
          return [...prev, { ...dataVisualizer, visualizationSize: 6 }];
        }

        return [...prev, dataVisualizer];
      });
      setIsOpenGraphAddModal(false);
    },
    []
  );

  const handleOnCloseGraphAddModal = useCallback(() => {
    setIsOpenGraphAddModal(false);
  }, []);

  const handleOnClickDeleteDataVisualizer = useCallback((id: string) => {
    setDataVisualizers((prev) => prev.filter((dv) => dv.questionId !== id));
  }, []);

  const handleOnClickResizeDataVisualizer = useCallback((id: string) => {
    setDataVisualizers((prev) => {
      return prev.map((dv) => {
        if (dv.questionId === id) {
          return {
            ...dv,
            visualizationSize: dv.visualizationSize
              ? dv.visualizationSize === 12
                ? 6
                : 12
              : 6
          };
        }
        return dv;
      });
    });
  }, []);

  const handleOnClickExport = useCallback(() => {
    /** Early abort */
    if (!survey) {
      console.error("handleOnClickExport: no survey");
      return false;
    }
    if (respondents.length === 0) {
      return false;
    }

    /** Csv data array */
    const exportData: typeof csvRespondents = [];

    /** Extract a map of question-full-id -> question-label */
    const mappedSurveyQuestions = mapQuestionsToCsvMap(
      survey,
      splitCsvWithOptionColumns
    );

    /** Push the headers */
    const headerLabels = Array.from(mappedSurveyQuestions.values());
    exportData.push(headerLabels);
    /** Extract header ids */
    const headerIds = Array.from(mappedSurveyQuestions.keys());

    /** Iterate over each respondent and for each header id, find the answer id and push its label */
    for (let i = 0; i < respondents.length; i++) {
      const respondent = respondents[i];

      const mappedRespondentAnswers = mapRespondentAnswers(
        respondent,
        splitCsvWithOptionColumns
      );

      const { meta } = respondent;
      exportData.push(
        headerIds.map((mqk) => {
          if (mappedRespondentAnswers[mqk]) {
            return mappedRespondentAnswers[mqk];
          }
          if (mqk === "responseTimestamp") {
            return meta.created.toDate().toLocaleString();
          }
          if (mqk === "respondentId") {
            return meta.fireId;
          }

          return "null";
        })
      );
    }

    /** Update the state with new data -> Triggers the download of the .csv file */
    setCsvRespondents(exportData);

    var worksheet = utils.aoa_to_sheet(exportData);
    var workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Results");
    writeFileXLSX(
      workbook,
      `mapx-export_${new Date().toISOString().split("T")[0]}_${
        survey.id?.split("/")[0]
      }-${survey.id?.split("/")[1]}.xlsx`
    );
  }, [survey, respondents, splitCsvWithOptionColumns]);

  useEffect(() => {
    if (id && shortId) {
      unsubscribeSurveys.current = fire.subscribeToSurvey({
        shortId,
        id,
        onSuccess: (fireSurvey) => {
          setSurvey(fireSurvey);
        },
        onError: (error) => {
          handleOnError();
        }
      });
    }
  }, [handleOnError, id, shortId]);

  useEffect(() => {
    if (id && shortId && survey) {
      unsubscribeAnswers.current = fire.subscribeToAnswers({
        id,
        shortId,
        onSuccess: (fireAnswers) => {
          const answers = convertFireAnswers(fireAnswers, survey);
          setRespondents(answers);
        },
        onError: (error) => {
          handleOnError();
        }
      });
    }
  }, [handleOnError, id, shortId, survey]);

  useEffect(() => {
    const nSubAnswers = filteredRespondents
      .map((answer) => answer.answers.length)
      .reduce((acc, curr) => acc + curr, 0);

    const nSubMapMarkers = filteredRespondents
      .map((answer) => answer.mapMarkers.length)
      .reduce((acc, curr) => acc + curr, 0);

    setNAnswerPoints(nSubAnswers);
    setNMapMarkers(nSubMapMarkers);
  }, [filteredRespondents]);

  useEffect(() => {
    /** Extract firestore created timestamps for each answer */
    const answersDates = filteredRespondents
      .map((answer) => answer.meta.created)
      .map((timestamp: Timestamp) => timestamp.toDate());

    /** Count the number of answers per day */
    const dateData = answersDates
      .reduce((acc, date) => {
        const dateIndex = acc.findIndex((d) => sameDay(d.date, date));

        if (dateIndex === -1) {
          acc.push({ date, value: 1 });
        } else {
          acc[dateIndex].value += 1;
        }

        return acc;
      }, [] as { date: Date; value: number }[])
      .map((d) => ({
        date: d.date.getTime(),
        value: d.value
      }))
      .sort((a, b) => a.date - b.date);

    setAnswerDateData(dateData);
  }, [filteredRespondents]);

  useEffect(() => {
    if (filters.length === 0) {
      setFilteredRespondents(respondents);
      updateDataVisualizersData(respondents);

      return;
    }

    const filteredRespondents = respondents.filter((r) => {
      return filters.every((f) => {
        const questionResponse = r.answers.find(
          (a: any) => a.question.id === f.questionId
        );
        /** If the question is not answered, return false */
        if (questionResponse === undefined) return false;
        const { answer } = questionResponse;
        if (isNaN(answer)) return false;
        if (f.filterComparison === FILTER_COMPARISON.IS) {
          return answer === f.filterValue;
        }
        if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
          return answer !== f.filterValue;
        }
        if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
          return answer.includes(f.filterValue);
        }
        return false;
      });
    });
    updateDataVisualizersData(filteredRespondents);
    setFilteredRespondents(filteredRespondents);
  }, [filters, respondents, updateDataVisualizersData]);

  return (
    <>
      <AnalyzeViewPage
        surveyTitle={survey?.name}
        surveyId={survey?.id}
        surveyMeta={survey?.meta}
        nAnswerPoints={nAnswerPoints}
        nMapMarkers={nMapMarkers}
        answerDateData={answerDateData}
        onClickExport={handleOnClickExport}
        exportAnswers={csvRespondents}
        flatExport={splitCsvWithOptionColumns}
        onChangeFlatExport={handleOnChangeFlatExport}
        onClickSignOut={handleOnClickSignOut}
        onClickAddDataVisualizer={handleOnClickAddDataVisualizer}
        onClickDeleteDataVisualizer={handleOnClickDeleteDataVisualizer}
        onClickResizeDataVisualizer={handleOnClickResizeDataVisualizer}
        onClickFilter={handleOnClickFilter}
        dataVisualizers={dataVisualizers}
        nRespondents={filteredRespondents.length}
        filterCount={filters?.length}
      />
      <GraphAddModal
        isOpen={isOpenGraphAddModal}
        onClose={handleOnCloseGraphAddModal}
        onAdd={handleOnClickAddGraph}
        respondents={filteredRespondents}
        survey={survey}
        addedDataVisualizers={dataVisualizers}
      />
      <FilterModal
        filters={filters}
        onClose={handleOnCloseFilterModal}
        isOpen={isOpenFilterModal}
        onClickAddFilter={handleOnClickAddFilter}
        onRemoveFilter={handleOnClickRemoveFilter}
        survey={survey}
      />
    </>
  );
};

export default AnalyzePage;
