import { useCallback } from "react";
import { DataVisualizerProps } from "./DataVisualizer.types";
import DataVisualizerView from "./DataVisualizer.view";

const DataVisualizer = (props: DataVisualizerProps) => {
  const { data, type, graphTitle, onClickDelete, onClickResize, graphId } =
    props;

  const handleOnClickDelete = useCallback(() => {
    if (onClickDelete && graphId) {
      onClickDelete(graphId);
    }
  }, [graphId, onClickDelete]);

  const handleOnClickResize = useCallback(() => {
    if (onClickResize && graphId) {
      onClickResize(graphId);
    }
  }, [graphId, onClickResize]);

  return (
    <DataVisualizerView
      data={data}
      graphId={graphId}
      type={type}
      graphTitle={graphTitle}
      onClickDelete={handleOnClickDelete}
      onClickResize={handleOnClickResize}
    />
  );
};

export default DataVisualizer;
