import { useNavigate, useParams } from "react-router-dom";
import SurveyPageProps from "./SurveyPage.types";
import { useCallback, useEffect, useRef, useState } from "react";
import { fire } from "services";
import SurveyPageView from "./SurveyPage.view";
import { useImmer } from "use-immer";
import { Page, Survey } from "interfaces";
import { Unsubscribe } from "firebase/firestore";
import { QUESTION_TYPE, TOOLMODE } from "enums";
import { Question } from "types";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const SurveyPage = (props: SurveyPageProps) => {
  const { id, shortId } = useParams();
  const navigate = useNavigate();

  const unsubscribeSurveys = useRef<void | Unsubscribe>();

  const [survey, setSurvey] = useImmer<Survey | undefined>(undefined);
  const [page, setPage] = useState<Page | undefined>(() => {
    const page = localStorage.getItem("page");
    if (page) return JSON.parse(page);
    return undefined;
  });
  const [pageNumber, setPageNumber] = useState<number | undefined>(() => {
    const pageNumber = localStorage.getItem("pageNumber");
    if (pageNumber) return JSON.parse(pageNumber);
    return undefined;
  });
  const [nPages, setNPages] = useState<number | undefined>(() => {
    const nPages = localStorage.getItem("nPages");
    if (nPages) return JSON.parse(nPages);
    return undefined;
  });
  const [answers, setAnswers] = useImmer<{ [id: string]: any }>(() => {
    const answers = localStorage.getItem("answers");
    if (answers) return JSON.parse(answers);
    return {};
  });
  const [showSurvey, setshowSurvey] = useState<boolean>(() => {
    const showSurvey = localStorage.getItem("showSurvey");
    if (showSurvey) return JSON.parse(showSurvey);
    return true;
  });
  const [toolMode, setToolMode] = useState<TOOLMODE | undefined>(() => {
    const toolMode = localStorage.getItem("toolMode");
    if (toolMode) return JSON.parse(toolMode);
    return undefined;
  });
  const [mapSettings, setMapSettings] = useState(() => {
    const mapSettings = localStorage.getItem("mapSettings");

    if (mapSettings) return JSON.parse(mapSettings);

    return {
      latitude: 0,
      longitude: 0,
      zoom: 10
    };
  });
  const [mapQuestionId, setMapQuestionId] = useState<string | undefined>(() => {
    const mapQuestionId = localStorage.getItem("mapQuestionId");
    if (mapQuestionId) return JSON.parse(mapQuestionId);
    return undefined;
  });
  const [mapQuestions, setMapQuestions] = useState<Question[] | undefined>(
    () => {
      const mapQuestions = localStorage.getItem("mapQuestions");
      if (mapQuestions) return JSON.parse(mapQuestions);
      return undefined;
    }
  );
  const [mapMarkers, setMapMarkers] = useState<
    {
      id: string;
      latitude: number;
      longitude: number;
      answers: { [id: string]: any };
      color?: string;
    }[]
  >(() => {
    const mapMarkers = localStorage.getItem("mapMarkers");
    if (mapMarkers) return JSON.parse(mapMarkers);
    return [];
  });
  const [markerPlaceholderColor, setMarkerPlaceholderColor] = useState<
    string | undefined
  >(() => {
    const markerPlaceholderColor = localStorage.getItem(
      "markerPlaceholderColor"
    );
    if (markerPlaceholderColor) return JSON.parse(markerPlaceholderColor);
    return undefined;
  });
  const [openSentDialog, setOpenSentDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const clearStates = useCallback(() => {
    setAnswers({});
    setPageNumber(1);
    setToolMode(undefined);
    setshowSurvey(true);
    setMapQuestionId(undefined);
    setMapQuestions(undefined);
    setMapMarkers([]);
    setMapSettings({
      latitude: 0,
      longitude: 0,
      zoom: 10
    });
    localStorage.clear();
  }, [setAnswers]);

  const handleOnClickRestartSurvey = useCallback(() => {
    setOpenSentDialog(false);
    clearStates();
  }, [clearStates]);

  const handleOnClickEndSurvey = () => {
    setOpenSentDialog(false);
    clearStates();
    window.open("https://www.mapx.se", "_self");
  };

  const handleOnError = useCallback(() => {
    handleOnClickRestartSurvey();
    navigate("/error");
  }, [handleOnClickRestartSurvey, navigate]);

  const handleOnClickNextPage = () => {
    if (pageNumber && nPages && pageNumber < nPages) {
      setPageNumber(pageNumber + 1);
      setPage(survey?.pages[pageNumber]);
    }
  };

  const handleOnClickBackPage = () => {
    if (pageNumber && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      setPage(survey?.pages[pageNumber - 2]);
    }
  };

  const handleOnChangeInputQuestion = (id: string, value: string) => {
    setAnswers((draft) => {
      draft[id] = value;
    });

    if (toolMode === TOOLMODE.MAP_MARKER) setToolMode(undefined);
  };

  const handleOnChangeRadioQuestion = (id: string, value: string) => {
    setAnswers((draft) => {
      draft[id] = value;
    });

    if (toolMode === TOOLMODE.MAP_MARKER) setToolMode(undefined);
  };

  const handleOnChangeDropdownQuestion = (id: string, value: string) => {
    setAnswers((draft) => {
      draft[id] = value;
    });

    if (toolMode === TOOLMODE.MAP_MARKER) setToolMode(undefined);
  };

  const handleOnChangeRadioMapQuestion = (
    id: string,
    value: string,
    map: {
      latitude: number;
      longitude: number;
      zoom: number;
    }
  ) => {
    setAnswers((draft) => {
      draft[id] = value;
    });

    setMapSettings(map);

    if (toolMode === TOOLMODE.MAP_MARKER) setToolMode(undefined);
  };

  const handleOnChangeRadioMultiQuestion = (id: string, value: string) => {
    setAnswers((draft) => {
      if (draft[id]) {
        if (!draft[id].includes(value)) {
          draft[id] = [...(draft[id] || []), value];
        } else {
          draft[id] = (draft[id] || []).filter((x: string) => x !== value);
        }
      } else {
        draft[id] = [value];
      }
    });

    if (toolMode === TOOLMODE.MAP_MARKER) setToolMode(undefined);
  };

  const handleOnClickMapQuestionOption = (id: string, value: string) => {
    setToolMode(TOOLMODE.MAP_MARKER);
    setshowSurvey(false);

    if (page) {
      const question = page.questions.find((q) => q.id === id);
      if (question && question.type === QUESTION_TYPE.MAP_MARKER) {
        const { questions, options } = question;
        setMapQuestionId(id);
        setMapQuestions(questions);
        if (options) {
          const option = options.find((o) => o.id === value);
          if (option) {
            setMarkerPlaceholderColor(option.backgroundColor);
          }
        }
      }
    }
  };

  const handleOnClickSend = () => {
    if (id && shortId && survey?.fireId) {
      setLoading(true);
      const loadingSnack = enqueueSnackbar("Dina svar sparas...", {
        variant: "info",
        autoHideDuration: null
      });
      fire
        .sendAnswer({
          surveyFireId: survey?.fireId,
          surveyId: `${shortId}/${id}`,
          answers,
          mapMarkers
        })
        .then(() => {
          enqueueSnackbar("Sparat!", {
            variant: "success",
            autoHideDuration: 3000
          });
          setOpenSentDialog(true);
        })
        .catch((error) => {
          closeSnackbar(loadingSnack);
          enqueueSnackbar(
            "Något gick fel, ta kontakt med oss för att få hjälp (info@mapx.se)",
            { variant: "error", autoHideDuration: 6000 }
          );
          console.log(error);
        })
        .finally(() => {
          closeSnackbar(loadingSnack);
          setLoading(false);
        });
    } else {
      enqueueSnackbar(
        "Något gick fel, ta kontakt med oss för att få hjälp (info@mapx.se)",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    page && localStorage.setItem("page", JSON.stringify(page));
  }, [page]);

  useEffect(() => {
    pageNumber &&
      localStorage.setItem("pageNumber", JSON.stringify(pageNumber));
  }, [pageNumber]);

  useEffect(() => {
    nPages && localStorage.setItem("nPages", JSON.stringify(nPages));
  }, [nPages]);

  useEffect(() => {
    answers && localStorage.setItem("answers", JSON.stringify(answers));
  }, [answers]);

  useEffect(() => {
    localStorage.setItem("showSurvey", JSON.stringify(showSurvey));
  }, [showSurvey]);

  useEffect(() => {
    toolMode && localStorage.setItem("toolMode", JSON.stringify(toolMode));
  }, [toolMode]);

  useEffect(() => {
    mapSettings &&
      localStorage.setItem("mapSettings", JSON.stringify(mapSettings));
  }, [mapSettings]);

  useEffect(() => {
    mapQuestionId &&
      localStorage.setItem("mapQuestionId", JSON.stringify(mapQuestionId));
  }, [mapQuestionId]);

  useEffect(() => {
    mapQuestions &&
      localStorage.setItem("mapQuestions", JSON.stringify(mapQuestions));
  }, [mapQuestions]);

  useEffect(() => {
    mapMarkers &&
      localStorage.setItem("mapMarkers", JSON.stringify(mapMarkers));
  }, [mapMarkers]);

  useEffect(() => {
    markerPlaceholderColor &&
      localStorage.setItem(
        "markerPlaceholderColor",
        JSON.stringify(markerPlaceholderColor)
      );
  }, [markerPlaceholderColor]);

  useEffect(() => {
    if (id && shortId) {
      unsubscribeSurveys.current = fire.subscribeToSurvey({
        shortId,
        id,
        onSuccess: (survey) => {
          if (survey.pages.length > 0) {
            // Minor hax
            setPageNumber((prev) => {
              const newPageNr = prev && prev > 1 ? prev : 1;

              setPage(survey.pages[newPageNr - 1]);

              return newPageNr;
            });

            setNPages(survey.pages.length);
          }
          setSurvey(survey);

          if (
            survey?.settings?.map &&
            !(mapSettings.latitude !== 0 && mapSettings.longitude !== 0)
          )
            setMapSettings(survey.settings.map);
        },
        onError: (error) => {
          handleOnError();
        }
      });
    } else {
      handleOnError();
    }

    return () => {
      if (unsubscribeSurveys.current) unsubscribeSurveys.current();
    };
  }, [
    handleOnClickRestartSurvey,
    handleOnError,
    id,
    mapSettings.latitude,
    mapSettings.longitude,
    setSurvey,
    shortId
  ]);

  return (
    <SurveyPageView
      answers={answers}
      page={page}
      pageNumber={pageNumber}
      nPages={nPages}
      onClickNextPage={handleOnClickNextPage}
      onClickBackPage={handleOnClickBackPage}
      onClickShowSurvey={() => {
        setshowSurvey(!showSurvey);
      }}
      onClickExitMarkerPlaceholder={() => {
        setToolMode(undefined);
        setshowSurvey(true);
      }}
      onChangeInputQuestion={handleOnChangeInputQuestion}
      onChangeDropdownQuestion={handleOnChangeDropdownQuestion}
      onChangeRadioQuestion={handleOnChangeRadioQuestion}
      onChangeRadioMapQuestion={handleOnChangeRadioMapQuestion}
      onChangeRadioMultiQuestion={handleOnChangeRadioMultiQuestion}
      onClickMapQuestionOption={handleOnClickMapQuestionOption}
      onClickSend={handleOnClickSend}
      mapSettings={mapSettings}
      showSurvey={showSurvey}
      toolMode={toolMode}
      mapQuestions={mapQuestions}
      mapMarkers={mapMarkers}
      onClickConfirmNewMarker={(markerId, answers, coordinates) => {
        setAnswers((draft) => {
          if (mapQuestionId) {
            draft[mapQuestionId] = {
              ...draft[mapQuestionId],
              [markerId]: answers
            };

            if (coordinates)
              setMapMarkers((prev) => {
                return [
                  ...prev,
                  {
                    id: markerId,
                    latitude: coordinates.latitude,
                    longitude: coordinates.longitude,
                    answers,
                    color: markerPlaceholderColor
                  }
                ];
              });
          }
        });
        setToolMode(undefined);
        setshowSurvey(true);
        setMarkerPlaceholderColor(undefined);
      }}
      onClickConfirmUpdateMarker={(markerId, answers) => {
        setAnswers((draft) => {
          const questionId = Object.keys(draft).find((id) =>
            Object.keys(draft[id]).includes(markerId)
          );
          if (questionId) {
            draft[questionId] = {
              ...draft[questionId],
              [markerId]: { ...draft[questionId][markerId], ...answers }
            };
          }
        });
        setMapMarkers((prev) => {
          return prev.map((marker) => {
            if (marker.id === markerId) {
              return { ...marker, answers };
            }
            return marker;
          });
        });
        setToolMode(undefined);
        setshowSurvey(true);
        setMarkerPlaceholderColor(undefined);
      }}
      onClickDeleteMarker={(markerId) => {
        setAnswers((draft) => {
          const questionId = Object.keys(draft).find((id) =>
            Object.keys(draft[id]).includes(markerId)
          );
          if (questionId) {
            delete draft[questionId][markerId];
          }
        });
        setMapMarkers((prev) => {
          return prev.filter((marker) => marker.id !== markerId);
        });
      }}
      markerPlaceholderColor={markerPlaceholderColor}
      openSentDialog={openSentDialog}
      onClickEndSurvey={handleOnClickEndSurvey}
      onClickRestartSurvey={() => {
        handleOnClickRestartSurvey();
        setPage(survey?.pages[0]);
      }}
      loading={loading}
    />
  );
};

export default SurveyPage;
