import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { fire } from "../../services";
import LoginPageView from "./LoginPage.view";
import LoginProps from "./LoginPage.types";

const LoginPage = (props: LoginProps) => {
  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleOnClickLogin = () => {
    fire
      .signIn(email, password)
      .then((value) => {
        navigate(from, { replace: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <LoginPageView
      email={email}
      password={password}
      onClickLogin={handleOnClickLogin}
      onChangeEmail={setEmail}
      onChangePassword={setPassword}
    />
  );
};

export default LoginPage;
